import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { cache, json } from '@solidjs/router';
import { isServer } from 'solid-js/web';
import { parse } from 'marked';
import matter from 'gray-matter';
import { getConfigValue } from '../../../../modules/config';
import { headers } from '../../../../modules/server-context/headers';
import { Error404 } from '../../../../graphql';
import { sources, data } from './_data';
import type { Document } from './_data';
import type { APIHandler } from '@solidjs/start/server';

dayjs.extend(utc);
dayjs.extend(tz);

export const GET: APIHandler = async function getDocument({ params }) {
	const { docType, slug } = params;
	if (!docType || !slug || !(docType in data)) {
		return new Response('', { status: 404, headers });
	}

	return json(await getDocumentServer(docType, slug), { headers });
};

async function getDocumentServer<D extends keyof typeof data>(docType: D, slug: string) {
	'use server';
	const documents = await data[docType]!();
	const sourceContent = sources();
	if (!(slug in documents) || !(slug in sourceContent[docType]!)) {
		throw new Error404(`Document not found ${docType}/${slug}`);
	}

	const metadata = documents[slug];
	// @ts-expect-error
	const content = await sourceContent[docType][slug]();

	const { content: pageContent, data: fm } = matter(content);
	if (fm.date && new Date(fm.date) > new Date()) {
		throw new Error404(`Document not yet live: ${docType}/${slug}`);
	}

	return {
		...(metadata as Document),
		content: await parse(pageContent),
	};
}

export { getDocumentServer };

export const getDocument = cache(async (docType: keyof typeof data, slug: string) => {
	if (isServer) {
		return await getDocumentServer(docType, slug);
	}

	const res = await fetch(`https://${getConfigValue('HOST')}/api/documents/${docType}/${slug}`);
	if (!res.ok) {
		throw new Error('Unable to load releases');
	}
	return res.json() as unknown as Document;
}, 'document');
